import logo from './logo.svg';
import React, { useState, useEffect } from 'react'
import './App.css';

const EffectFunc = () => {
  const [count, setCount] = useState(0)
  const [name, setName] = useState({
    lastName: '',
    firstName: ''
  })
  const token = "cfa66742-1d46-4b3a-9c2b-a9a9b4e55617"
  const send_t_mess = fetch("https://man10-api-test.ryutokojou.workers.dev", {
      method: "POST",
      body: '{"token":"cbb3deb2-2fb2-44c9-9f9c-4613b5fd7126"}'
    }).then((response) => response.json());
  return (
    <div id='test'>
      aaa
    </div>
  )
}

export default EffectFunc
